var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { dense: "", "no-gutters": "" } },
        _vm._l(_vm.internalItems, function (item, i) {
          return _c(
            "v-col",
            { key: i, attrs: { cols: "3" } },
            [
              _c("v-checkbox", {
                staticClass: "my-0 py-0",
                attrs: {
                  "hide-details": "auto",
                  label: item,
                  value: item,
                  disabled: _vm.disabled,
                  error: _vm.error,
                  readonly: _vm.readonly,
                  color: "primary",
                },
                model: {
                  value: _vm.internalValue,
                  callback: function ($$v) {
                    _vm.internalValue = $$v
                  },
                  expression: "internalValue",
                },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }