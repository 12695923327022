var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.survey, function (s, i) {
      return _c(
        "div",
        { key: i },
        [
          _c(
            "v-row",
            { staticClass: "m-0 p-0 pt-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "subtitle-1 font-weight-bold py-0 my-0",
                      class: s.isAnswered ? "green--text" : "red--text",
                    },
                    [
                      s.isAnswered
                        ? _c(
                            "v-icon",
                            { attrs: { left: "", color: "green white--text" } },
                            [_vm._v("mdi-check-circle")]
                          )
                        : _c(
                            "v-icon",
                            { attrs: { left: "", color: "red white--text" } },
                            [_vm._v("mdi-close-circle")]
                          ),
                      _vm._v(" " + _vm._s(s.description) + " "),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "caption py-0 my-0 mb-4" }, [
                    _vm._v(_vm._s(s.subtitle)),
                  ]),
                  s.optiontype == "radio"
                    ? _c("radiobuttongroup", {
                        attrs: {
                          disabled: _vm.disabled,
                          options: s.options,
                          value: s.optionvalue,
                          seperator: s.seperator,
                          error: s.error,
                          readonly: s.readonly,
                          editable: s.editable,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.OptionChanged($event, s.id)
                          },
                        },
                      })
                    : _vm._e(),
                  s.optiontype == "checkbox"
                    ? _c("checkboxgroup", {
                        attrs: {
                          disabled: _vm.disabled,
                          options: s.options,
                          value: s.optionvalue,
                          seperator: s.seperator,
                          error: s.error,
                          readonly: s.readonly,
                          editable: s.editable,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.OptionChanged($event, s.id)
                          },
                        },
                      })
                    : _vm._e(),
                  s.textexists == "E"
                    ? _c("v-textarea", {
                        class: s.optiontype == "" ? "mt-0" : "mt-3",
                        attrs: {
                          disabled: _vm.disabled,
                          value: s.text,
                          outlined: "",
                          "hide-details": "auto",
                          color: "secondary",
                          placeholder: "Açıklama",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.ChangeText($event, s.id)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }