var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-radio-group",
        {
          staticClass: "my-0 py-0",
          attrs: { row: "", "hide-details": "auto" },
          model: {
            value: _vm.internalValue,
            callback: function ($$v) {
              _vm.internalValue = $$v
            },
            expression: "internalValue",
          },
        },
        _vm._l(_vm.internalOptions, function (item, i) {
          return _c("v-radio", {
            key: i,
            staticClass: "m-0 py-0",
            staticStyle: { width: "25%" },
            attrs: {
              "hide-details": "auto",
              value: item,
              label: item,
              disabled: _vm.disabled,
              error: _vm.error,
              readonly: _vm.readonly,
              color: "secondary",
              dense: "",
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }